import MondayMobile from '../assets/playground-images/monday-mobile.png';
// import MondayTypo from '../assets/playground-images/monday.png';
// import TuesdayTypo from '../assets/playground-images/tuesday.png';
// import TuesdayMobile from '../assets/playground-images/tuesday-mobile.png';
// import WednesdayTypo from '../assets/playground-images/wednesday.png';
// import WednesdayMobile from '../assets/playground-images/wednesday-mobile.png';
// import ThursdayTypo from '../assets/playground-images/thursday.png';
// import ThursdayMobile from '../assets/playground-images/thursday-mobile.png';
// import FridayTypo from '../assets/playground-images/friday.png';
// import FridayMobile from '../assets/playground-images/friday-mobile.png';
// import SaturdayTypo from '../assets/playground-images/saturday.png';
// import SaturdayMobile from '../assets/playground-images/saturday-mobile.png';
// import SundayTypo from '../assets/playground-images/sunday.png';
// import SundayMobile from '../assets/playground-images/sunday-mobile.png';
import MondayCover from '../assets/playground-images/monday-cover.png';
import MondayVideo from '../assets/playground-images/monday-video.mp4';
import TuesdayCover from '../assets/playground-images/tuesday-cover.png';
import TuesdayVideo from '../assets/playground-images/tuesday-video.mp4';
import WednesdayCover from '../assets/playground-images/wednesday-cover.png';
import WednesdayVideo from '../assets/playground-images/wednesday-video.mp4';
import ThursdayVideo from '../assets/playground-images/thursday-video.mp4';
// import FridayVideo from '../assets/playground-images/friday-video.mp4';

export const playgroundCategories = {
  blender: 'Blender',
  design: '3D Illustration Blender',
  creativeCoding: '3D Illustration Blender',
};

export const playgroundProjects = [
  {
    id: 0,
    category: playgroundCategories.blender,
    title: 'Typography in motion',
    year: '2024',
    cover: MondayCover,
    mobileCover: MondayMobile,
    gif: MondayVideo,
    sizeClass: 'size-medium',
  },
  {
    id: 1,
    category: playgroundCategories.blender,
    title: 'Typography in motion',
    year: '2024',
    cover: TuesdayCover,
    mobileCover: MondayMobile,
    gif: TuesdayVideo,
    sizeClass: 'size-medium',
  },
  {
    id: 2,
    category: playgroundCategories.blender,
    title: 'Typography in motion',
    year: '2024',
    cover: WednesdayCover,
    mobileCover: WednesdayVideo,
    gif: WednesdayVideo,
    sizeClass: 'size-small',
  },
  {
    id: 3,
    category: playgroundCategories.blender,
    title: 'Typography in motion',
    year: '2024',
    cover: MondayCover,
    mobileCover: MondayMobile,
    gif: ThursdayVideo,
    sizeClass: 'size-large',
  },
  // {
  //   id: 4,
  //   category: playgroundCategories.blender,
  //   title: 'Typography in motion',
  //   year: '2024',
  //   cover: MondayMobile,
  //   gif: FridayVideo,
  //   sizeClass: 'size-large',
  // },
  // {
  //   id: 1,
  //   category: playgroundCategories.blender,
  //   title: '3D - Livin Room',
  //   year: '2023',
  //   cover: TuesdayTypo,
  //   mobileCover: TuesdayMobile,
  // },
  // {
  //   id: 2,
  //   category: playgroundCategories.blender,
  //   title: '3D - Office Room',
  //   year: '2023',
  //   cover: WednesdayTypo,
  //   mobileCover: WednesdayMobile,
  // },
  // {
  //   id: 3,
  //   category: playgroundCategories.blender,
  //   title: '3D - Office Room',
  //   year: '2023',
  //   cover: ThursdayTypo,
  //   mobileCover: ThursdayMobile,
  // },
  // {
  //   id: 4,
  //   category: playgroundCategories.blender,
  //   title: '3D - Office Room',
  //   year: '2023',
  //   cover: FridayTypo,
  //   mobileCover: FridayMobile,
  // },
  // {
  //   id: 5,
  //   category: playgroundCategories.blender,
  //   title: '3D - Office Room',
  //   year: '2023',
  //   cover: SaturdayTypo,
  //   mobileCover: SaturdayMobile,
  // },
  // {
  //   id: 6,
  //   category: playgroundCategories.blender,
  //   title: '3D - Office Room',
  //   year: '2023',
  //   cover: SundayTypo,
  //   mobileCover: SundayMobile,
  // },
  // {
  //   id: 6,
  //   category: playgroundCategories.blender,
  //   title: '3D - Office Room',
  //   year: '2023',
  //   cover: LowPoly,
  //   mobileCover: SundayMobile,
  // },
  // {
  //   id: 3,
  //   category: playgroundCategories.blender,
  //   title: '3D - Bedroom Room',
  //   year: '2023',
  //   cover: BedroomDesktop,
  //   mobileCover: BedroomMobile,
  // },
  // {
  //   id: 1,
  //   category: playgroundCategories.blender,
  //   title: '3D - Office Room',
  //   year: '2023',
  //   cover: OfficeLowPoly,
  // },
  // {
  //   id: 2,
  //   category: playgroundCategories.blender,
  //   title: '3D - Office Room',
  //   year: '2023',
  //   cover: OfficeDetailTwoLowPoly,
  // },
  // {
  //   id: 3,
  //   category: playgroundCategories.blender,
  //   title: '3D - Santa Claus',
  //   year: '2023',
  //   cover: NoelGroup,
  // },
  // {
  //   id: 4,
  //   category: playgroundCategories.blender,
  //   title: '3D - Santa Claus',
  //   year: '2023',
  //   cover: NoelTwo,
  // },
  // {
  //   id: 5,
  //   category: playgroundCategories.blender,
  //   title: '3D - Santa Claus',
  //   year: '2023',
  //   cover: NoelOne,
  // },
  // {
  //   id: 6,
  //   category: playgroundCategories.blender,
  //   title: '3D - Bedroom',
  //   year: '2023',
  //   cover: BedroomDetailOneLowPoly,
  // },
  // {
  //   id: 7,
  //   category: playgroundCategories.blender,
  //   title: '3D - Bedroom',
  //   year: '2023',
  //   cover: BedroomLowPoly,
  //   // onHover: BedroomNight,
  // },
  // {
  //   id: 8,
  //   category: playgroundCategories.blender,
  //   title: '3D - Bedroom',
  //   year: '2023',
  //   cover: BedroomDetailTwoLowPoly,
  // },
  // {
  //   id: 9,
  //   category: playgroundCategories.blender,
  //   title: '3D - Icopop',
  //   year: '2023',
  //   cover: IcoPopOne,
  // },
  // {
  //   id: 10,
  //   category: playgroundCategories.blender,
  //   title: '3D - Icopop',
  //   year: '2023',
  //   cover: IcoPopTwo,
  // },
  // {
  //   id: 11,
  //   category: playgroundCategories.blender,
  //   title: '3D - Icopop',
  //   year: '2023',
  //   cover: IcoPopThree,
  // },
  // {
  //   id: 12,
  //   category: playgroundCategories.blender,
  //   title: '3D - Living Room',
  //   year: '2023',
  //   cover: LivingRoomDetailOneLowPoly,
  // },
  // {
  //   id: 13,
  //   category: playgroundCategories.blender,
  //   title: '3D - Living Room',
  //   year: '2023',
  //   cover: LivingRoomLowPoly,
  // },
  // {
  //   id: 14,
  //   category: playgroundCategories.blender,
  //   title: '3D - Living Room',
  //   year: '2023',
  //   cover: LivingRoomDetailTwoLowPoly,
  // },
];
